// import React from 'react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import { BrowserRouter } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/base.css';
import './styles/sass/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);