import axios from "axios";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import formdata from "../assets/formdata.json";

const UploadData = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("Success!");
    const [formData, setFormData] = useState(
        {
        county: "",
        data1: [
            {
                image: "",
                title: "Avaerage Contribution to GDP (2013 - 2017)",
                value: ""
            },
            {
                image: "",
                title: "Key Economic Activity",
                value: ""
            },
            {
                image: "",
                title: "Gross County Product 2017 (in millions Kshs.)",
                value: ""
            },
            {
                image: "",
                title: "County Share of Gross Value Added and Grosss County Product (GCP) by Economic Activity, 2017",
                value: ""
            }
        ],
        data2: [
            {
                title: "Annual mean capita expenditure 2016 (Kshs)",
                value: ""
            },
            {
                title: "Mean real per capita expenditure 2016 (Kshs)",
                value: ""
            },
            {
                title: "GINI Coefficient 2015/16",
                value: ""
            },
            {
                title: "Absolute Poverty 2015/2016 (%)",
                value: ""
            }
        ],
        population: [
            {
                gender: "Male",
                all_population: "",
                urban_population: "",
                rural_population: ""
            },
            {
                gender: "Female",
                all_population: "",
                urban_population: "",
                rural_population: ""
            },
            {
                gender: "Intersex",
                all_population: "",
                urban_population: "",
                rural_population: ""
            },
            {
                gender: "All Genders",
                all_population: "",
                urban_population: "",
                rural_population: ""
            }
        ]
    });

    // const handleChange = e => {

    //     console.log(e.target.name);

    //     const nameArray = e.target.name.split(".");

    //     let key = "";

    //     nameArray.forEach((item, index) => {
    //         if (Number.isInteger(parseInt(item))) {
    //             key += `[${item}]`;
    //         } else {
    //             key += `.${item}`;
    //         }
    //     });

    //     key = key.replace(/^[.]/, "");

    //     setFormData({
    //         ...formData,
    //         [key]: e.target.value

    //     });
    // };

    // const handleChange = e => {
    //     const nameArray = e.target.name.split(".");

    //     const key = nameArray.reduce((obj, name, index) => {
    //         if (index === nameArray.length - 1) {
    //             return {
    //                 ...obj,
    //                 [name]: e.target.value
    //             };
    //         }
    //         return obj[name];
    //     }, formData);

    //     console.log(key);

    //     setFormData(key);
    // };

    const handleChange = e => {

        if (!e.target.name.includes(".")) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
            return;
        } else {
            const nameArray = e.target.name.split(".");

            // Create a copy of the form data to avoid mutating the state directly
            const newFormData = { ...formData };

            let data = newFormData[nameArray[0]];
            let value = e.target.value;

            for (let i = 1; i < nameArray.length - 1; i++) {
                data = data[nameArray[i]];
            }

            data[nameArray[nameArray.length - 1]] = value;

            setFormData(newFormData);
        }

    };

    const handleSubmit = e => {
        e.preventDefault();
        console.log("To be uploaded", formData);
        // Make an API call to the PHP script to store the data to the database

        axios.post("https://dma.api.sand-box.online", formData,
        ).then(res => {
            console.log('Response', res);
            setShowAlert(true);
            setAlertType(res.data.success == true ?"success":"danger");
            setAlertMessage(res.data.success == true?res.data.message:"An error occured while uploading the data");
            setTimeout(() => {
                setShowAlert(false);
            }, 4000); // 4 seconds

            // Reset the form data
            if (res.data.success == true)
            setFormData(formdata);

        });



    };

    const inputStyle = { marginRight: "10px" }

    return (
        <div className="wrapper">
            {showAlert && (
                <Alert variant={alertType}>
                    {/* <Alert.Heading>Success!</Alert.Heading> */}
                    <p>
                        {alertMessage}
                    </p>
                </Alert>
            )}
            <div className="content-section">
                <Container>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="County"
                            name="county"
                            value={formData.county}
                            onChange={handleChange}
                            style={{ "marginBottom": "20px" }}
                            required
                        />
                        <div className="oc-card-sectors" style={{ "marginBottom": "20px" }}>
                            <div className="oc-card">
                                {formData.data1.map((item, index) => (
                                    <div key={index} style={{ display: "flex", flexDirection: "row", margin: "10px 0" }} >
                                        {/* <input
                        type="text"
                        placeholder={`Image for data1 item ${index + 1}`}
                        name={`data1.${index}.image`}
                        value={item.image}
                        onChange={handleChange}
                    /> */}
                                        <input
                                            type="text"
                                            placeholder={`Title for data1 item ${index + 1}`}
                                            name={`data1.${index}.title`}
                                            value={item.title}
                                            onChange={handleChange}
                                            style={inputStyle}
                                            disabled
                                        />
                                        <input
                                            type="text"
                                            placeholder={"Enter value"}
                                            name={`data1.${index}.value`}
                                            value={item.value}
                                            onChange={handleChange}
                                            style={inputStyle}
                                            required
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="oc-card">
                                {formData.data2.map((item, index) => (
                                    <div key={index} style={{ display: "flex", flexDirection: "row", margin: "10px 0" }} >
                                        <input
                                            type="text"
                                            placeholder={`Title for data2 item ${index + 1}`}
                                            name={`data2.${index}.title`}
                                            value={item.title}
                                            onChange={handleChange}
                                            style={inputStyle}
                                            disabled
                                        />
                                        <input
                                            type="text"
                                            placeholder={"Enter value"}
                                            name={`data2.${index}.value`}
                                            value={item.value}
                                            onChange={handleChange}
                                            style={inputStyle}
                                            required
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <h3>Population</h3>
                        <div className="oc-card-sectors" style={{ "marginBottom": "20px" }}>
                            <div className="oc-card">
                                {formData.population.map((item, index) => (
                                    <>
                                        <div> {item.gender} </div>
                                        <div key={index} style={{ display: "flex", flexDirection: "row", margin: "10px 0" }} >
                                            <input
                                                type="text"
                                                placeholder="Total"
                                                name={`population.${index}.all_population`}
                                                value={item.all_population}
                                                onChange={handleChange}
                                                style={inputStyle}
                                                required

                                            />
                                            <input
                                                type="text"
                                                placeholder="Urban"
                                                name={`population.${index}.urban_population`}
                                                value={item.urban_population}
                                                onChange={handleChange}
                                                style={inputStyle}
                                                required

                                            />
                                            <input
                                                type="text"
                                                placeholder="Rural"
                                                name={`population.${index}.rural_population`}
                                                value={item.rural_population}
                                                onChange={handleChange}
                                                style={inputStyle}
                                                required

                                            />
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </Container>
            </div>
        </div>
    );
};

export default UploadData;