import React, { useState, useCallback, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import geojson from "../assets/kenyan-counties.geojson.json";
import mockdata1 from "../assets/mockdata1.json";
import "leaflet/dist/leaflet.css";
import Paper from '@mui/material/Paper';
// Import image assets
import maleIcon from '../images/icons/male.png';
import femaleIcon from '../images/icons/female.png'
import intersexIcon from '../images/icons/intersex_2.png'
import scaleIcon from '../images/icons/weighing_scale.png'
import financeIcon from '../images/icons/finance.png'


import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

export default function Homepage() {

  const center = [0.0236, 37.9062];

  const [activeCounty, setActiveCounty] = useState("dfs");

  const [data, setData] = useState([]);

  const countyStyle = {
    fillColor: "#d8e1e7",
    weight: 1,
    color: "#ffffff",
    fillOpacity: 1.0,
  };

  const participatingCountyStyle = {
    fillColor: "#d2212e",
    weight: 1,
    color: "#ffffff",
    fillOpacity: 1.0,
  }

  const activeCountyStyle = {
    fillColor: "#880808",
    weight: 2,
    color: "#ffffff",
    fillOpacity: 1.0,
  }

  function getVoivodeshipName(feature, layer) {
    if (feature.properties && feature.properties.COUNTY) {
      layer.bindPopup(feature.properties.COUNTY);
    }
  }

  function isCountyParticipating(string) {
    const isParticipating = data.some(element => element.county.toLowerCase() === string.toLowerCase())
    return isParticipating
  }

  function onEachFeature(feature, layer) {
    const county = feature.properties.COUNTY;

    if (county.toLowerCase() === activeCounty.toLowerCase()) {
      // layer.bindPopup(county);

      layer.setStyle(isCountyParticipating(county) ? activeCountyStyle : countyStyle);
    } else {
      layer.setStyle(isCountyParticipating(county) ? participatingCountyStyle : countyStyle);
    }


    layer.on("mouseover", function (e) {
      getVoivodeshipName(feature, layer);
      this.openPopup();
    });
    layer.on("mouseout", function () {
      this.closePopup();
    });
    layer.on("click", function (e) {
      handleCountyClick(feature.properties.COUNTY)
    });

  }

  function handleCountyClick(county) {
    setActiveCounty(county);
  }

  // Function to get data from the API using axios
  const getData = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://dma.api.sand-box.online/index.php"
      );
      if (response.data.success == true) {
        setData(response.data.counties);
        setActiveCounty("df");
      }
      else { }
    } catch (error) {
      console.log(error);
    }
  }, []);

  // useEffect to call the getData function
  useEffect(() => {
    getData();
  }, [getData]);


  return (
    <div className="wrapper">
      <div className="content-section">
        <Container>
          <h2 className="styled-heading styled-heading-large">
            PARTICIPATING COUNTIES STATISTICS
          </h2>

          <Row className="mb-0 mb-md-4">
            <Col lg="5">
              <div className="oc-map-small">
                <MapContainer
                  center={center}
                  zoom={6}
                  scrollWheelZoom={false}
                  style={{ width: "100%", height: "80vh" }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  <GeoJSON
                    data={geojson}
                    key={activeCounty}
                    onEachFeature={onEachFeature}
                  />
                </MapContainer>
              </div>
            </Col>

            <Col lg="7">
              <Row>
                {data
                .filter(d=>d.county.length>0)
                .map((item, index) => (
                  <Col lg="12" key={index}>
                    <div className="oc-accordion">
                      <Accordion activeKey={item.county.toLowerCase() === activeCounty.toLowerCase() ? index : null} flush>
                        <Accordion.Item eventKey={index} onClick={(e) => handleCountyClick(item.county)}>
                          <Accordion.Header >{item.county.toUpperCase()}</Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col lg="6">
                                <TableContainer component={Paper} sx={{ minWidth: 800, maxHeight: 510 }}>
                                  <Table stickyHeader size="small" aria-label='indicators' className='data-table'>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ backgroundColor: '#eff1f2', color: '#727e88' }}
                                          sx={{ border: 1, borderColor: '#efefef' }}>
                                          POPULATION
                                        </TableCell>
                                        <TableCell style={{ backgroundColor: '#eff1f2', color: '#727e88' }}
                                          sx={{ border: 1, borderColor: '#efefef' }}>
                                          Urban
                                        </TableCell>
                                        <TableCell style={{ backgroundColor: '#eff1f2', color: '#727e88' }}
                                          sx={{ border: 1, borderColor: '#efefef' }}>
                                          Rural
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item.population.map((datum, index) => (
                                        <TableRow key={index}>
                                          <TableCell style={{ backgroundColor: '#eff1f2' }}
                                            sx={{ border: 1, borderColor: '#efefef' }} component="th" scope="row">
                                            {datum.gender == "" ? "All Genders" : datum.gender}: {datum.all_population}
                                          </TableCell>
                                          <TableCell style={{ backgroundColor: '#eff1f2' }}
                                            sx={{ border: 1, borderColor: '#efefef' }} component="th" scope="row">
                                            {datum.urban_population}
                                          </TableCell>
                                          <TableCell style={{ backgroundColor: '#eff1f2' }}
                                            sx={{ border: 1, borderColor: '#efefef' }} component="th" scope="row">
                                            {datum.rural_population}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Col>
                              <Col lg="6">
                                <div className="oc-card-sectors">
                                  {item.data1.map((datum, index) => (
                                    <div className="oc-card-sector" key={index}>
                                      <div className="oc-card-sector-icon">
                                        <Link to={`/`}>
                                          <img
                                            src={financeIcon}
                                            alt="Image Here"
                                            height="47"
                                            width="47"
                                          />
                                        </Link>
                                      </div>
                                      <div className="oc-card-sector-txt" >
                                        <h6>
                                          <Link
                                            // style={{
                                            //   pointerEvents:
                                            //     sector.indicators.length === 0 ? "none" : "",
                                            // }}
                                            to={`/`}
                                          >
                                            {datum.title}
                                          </Link>
                                        </h6>
                                        {datum.value.includes("\n") ? (
                                          <ol>
                                            {datum.value.split("\n").map((v, index) => {
                                              return <li key={index}>{<span>{v}</span>}</li>
                                            })}
                                          </ol>
                                        ) : (<span>{datum.value}</span>)}


                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="icon-grid" style={{ "backgroundColor": "#f5faf6", "marginTop": "10px" }}>
                                  <div className="icon-grid-item">
                                    <img src={scaleIcon} />
                                    <h6 className="icon-grid-item-title"></h6>
                                    <p className="icon-grid-item-number" style={{ "color": "#32b8dee" }}>
                                      INEQUALITY TRENDS
                                    </p>
                                  </div>
                                  {item.data2.map((datum, index) => (
                                    <div className="icon-grid-item" key={index}>
                                      <h6 className="icon-grid-item-title">{datum.title}</h6>
                                      <p className="icon-grid-item-number">
                                        {datum.value}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}