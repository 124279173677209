import React, { Component } from 'react';
import Homepage from "./views/Homepage";
import { Routes, Route } from "react-router-dom";
import Layout from "./views/Layout";
import UploadData from './views/UploadData';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path="/upload" element={<UploadData />} />
      </Route>
    </Routes>
  );
}
